.section-masthead__inner
	position: relative
	overflow: hidden
	z-index: 50
.section-masthead__background_fullscreen, .section-masthead__background_halfscreen
	position: absolute
	top: 0
	left: 0
	right: 0
	bottom: 0
	overflow: hidden
.section-masthead__background_halfscreen-gutters
	padding: calc(var(--gutter-horizontal) + 40px) var(--gutter-horizontal) var(--gutter-horizontal)
.section-masthead__text
	display: inline-block
	max-width: 700px
.section-masthead__wrapper-scroll-down
	display: inline-block
	position: absolute
	bottom: 2px
	z-index: 50
.section-masthead__wrapper-scroll-down_center
	left: 0
	right: 0
	margin: auto
.section-masthead__wrapper-scroll-down_left
	left: calc(var(--gutter-horizontal) - 20px)
.section-masthead__wrapper-scroll-down_right
	right: calc(var(--gutter-horizontal) - 20px)
.section-masthead__overlay
	z-index: 1
	transform: scale(1.02) // prevent flicking on scroll
.section-masthead__header
	z-index: 50
.section-masthead__header_absolute
	position: absolute
	top: 50%
	left: 0
	right: 0
	transform: translateY(-50%)
	z-index: 50
.section-masthead__wrapper-info
	margin-top: 2em
	margin-bottom: 2em
	&:first-child
		margin-top: 0
	&:last-child
		margin-bottom: 0
.section-masthead__meta-item
	display: inline-block
	&:not(:last-child)
		&:after
			content: ''
			display: inline-block
			vertical-align: middle
			width: 60px
			@include fluid('width', 40, 60)
			height: 1px
			margin: 1em
			background-color: var(--color-gray-1)
	> *
		display: inline-block
		vertical-align: middle

.section-masthead__meta-categories
	+reset-ul
.section-masthead__bg
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	z-index: -1

@media screen and (max-width: $md)
	.section-masthead__wrapper-scroll-down
		bottom: 10px
	.section-masthead__background_halfscreen
		position: relative
		top: auto
		left: auto
		right: auto
		bottom: auto
		height: 100vh
		transform: none
	.section-masthead__background_halfscreen-gutters
		padding: 0
