.overflow
	position: relative
	overflow: hidden
.position-relative
	position: relative
.position-fixed
	position: fixed
.of-cover
	width: 100%
	height: 100%
	object-fit: cover
	font-family: 'object-fit: cover;'
.of-contain
	width: 100%
	height: 100%
	object-fit: contain
	font-family: 'object-fit: contain;'
.hidden
	opacity: 0
	visibility: hidden
.no-gutters
	padding-left: 0
	padding-right: 0
.grayscale
	filter: grayscale(70%)
.w-100
	width: 100% !important
.w-100vh
	width: 100vh !important
.w-100vw
	width: 100vw !important
.h-100
	height: 100% !important
.h-100vh
	height: 100vh !important
.h-100vw
	height: 100vw !important
.clearfix:after
	content: ''
	display: table
	clear: both
.backgroundblendmode
	.blend-difference
		mix-blend-mode: difference
		color: #fff
		a
			color: #fff
			&:hover
				opacity: .7
.hidden_absolute
	position: absolute
	top: -9999px
	left: -9999px
	opacity: 0
	visibility: hidden
#js-webgl
	display: none
.d-flex-centered
	display: flex
	align-items: center
	justify-content: center
.z-50
	z-index: 50
.z-100
	z-index: 100
.z-500
	z-index: 500
.z-1000
	z-index: 1000

.block-circle
	border-radius: 100%
.block-counter
	padding-left: 6px
	padding-right: 6px
	position: relative
.block-counter__counter
	position: absolute
	top: 0
	left: 100%
	@include fluid-type(13, 16)
	font-family: $font-secondary
	font-weight: bold
	color: $brown-grey
	white-space: nowrap
.col-gutters
	padding-top: 20px
	padding-bottom: 20px
	padding-left: 20px
	padding-right: 20px
.row-gutters
	margin-top: -20px
	margin-bottom: -20px
.pointer-events-none
	pointer-events: none
	*
		pointer-events: none
.border-radius-100
	border-radius: 100%
