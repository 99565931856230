.js-header-sticky
	transition: all 0.4s ease
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0)
	will-change: padding, box-shadow, background-color
.header_sticky
	position: fixed
	top: 0
	left: 0
	width: 100%
	padding-top: 15px
	padding-bottom: 15px
	box-shadow: 0px 0px 30px 0px rgba(24, 24, 24, 0.1)
	&[data-arts-header-sticky-logo="primary"]
		.logo__img-primary
			opacity: 1
			visibility: visible
		.logo__img-secondary
			opacity: 0
			visibility: hidden
		.logo__text-title
			color: var(--paragraph-color-dark)
		.logo__text-tagline
			color: var(--color-gray-1)
	&[data-arts-header-sticky-logo="secondary"]
		.logo__img-primary
			opacity: 0 
			visibility: hidden
		.logo__img-secondary
			opacity: 1
			visibility: visible
		.logo__text-title
			color: #fff
		.logo__text-tagline
			color: var(--color-gray-2)
